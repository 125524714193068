import { put, call, takeLatest, fork } from "redux-saga/effects"
import { AnalyticsEvents } from "analytics/events"
import { LEADS } from "./constants"
import { addLeadSuccess, addLeadFailure } from "./actions"
import { post$ } from "services/fetchService"
import { LEAD_API_URL, CHAT_ID } from "constants/index"
import { isEmpty } from "lodash"
import { pushToDataLayer } from "utils"

const SIMULATE_EXCEPTION = false // To test exception handling in UI

const CONTACT_VALUE = {
  phone: "зателефонувати",
  telegram: "написати в Telegram",
  whatsapp: "написати в WhatsApp",
  viber: "написати у Viber",
}

function buildLeadDescription(lead, formId) {
  const { messanger, comment, username, utmParams, deviceType } = lead
  let description = messanger ? `Написати у ${messanger}` : "зателефонувати"

  if (comment) {
    description += ` (Комментар: ${comment})`
  }

  if (username) {
    description += ` (Нікнейм: ${username})`
  }

  if (!isEmpty(utmParams)) {
    description += ` (utm-параметри сесії: ${JSON.stringify(utmParams)})`
  }

  if (deviceType) {
    description += ` (Девайс: ${deviceType})`
  }

  if (formId) {
    description += ` (Форма: ${formId})`
  }

  return description
}

function buildAnalyticsData(lead, messanger, formId) {
  const { utmParams, deviceType, comment, username, phoneNumber, name } = lead

  return {
    name,
    event: AnalyticsEvents.FORM_SUBMISSION,
    contactValue: messanger ? CONTACT_VALUE[messanger] : CONTACT_VALUE.phone,
    phoneNumber,
    username,
    comment,
    formId,
    utmSource: utmParams?.utm_source,
    utmMedium: utmParams?.utm_medium,
    deviceType,
  }
}

function* trackAnalytics(analyticsData) {
  pushToDataLayer(analyticsData)

  window.analytics?.ready(function () {
    window.analytics.track(AnalyticsEvents.FORM_SUBMISSION, analyticsData)
  })
  yield
}

function* addLeadSaga({ payload }) {
  try {
    const { lead, formId } = payload
    const description = buildLeadDescription(lead, formId)
    const url = `${LEAD_API_URL}/new-lead?name=${lead.name}&phoneNumber=${
      lead.phoneNumber?.trim() ?? "none"
    }&description=${description}&chatId=${CHAT_ID}`

    const analyticsData = buildAnalyticsData(lead, lead.messanger, formId)

    const response = SIMULATE_EXCEPTION
      ? { ok: false, error: "Simulated error message" }
      : yield call(post$, url, lead)

    if (response.ok) {
      yield put(addLeadSuccess(formId))
    } else {
      yield put(addLeadFailure(response.error, formId))
    }

    yield fork(trackAnalytics, analyticsData)
  } catch (error) {
    yield put(addLeadFailure(error.message, payload.formId))
  }
}

const leadsSaga = [takeLatest(LEADS.ADD.IDLE, addLeadSaga)]

export default leadsSaga
