import { createReducer } from "utils/store"

import { SHOW_HEADER, HIDE_HEADER } from "./constants"

const initialState = {
  isVisible: true,
}

export const handlers = {
  [SHOW_HEADER]: state => ({ ...state, isVisible: true }),
  [HIDE_HEADER]: state => ({ ...state, isVisible: false }),
}

export default createReducer(initialState, handlers)
