exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-feature-it-english-js": () => import("./../../../src/pages/feature/it-english.js" /* webpackChunkName: "component---src-pages-feature-it-english-js" */),
  "component---src-pages-feature-love-english-js": () => import("./../../../src/pages/feature/love-english.js" /* webpackChunkName: "component---src-pages-feature-love-english-js" */),
  "component---src-pages-feature-personal-program-js": () => import("./../../../src/pages/feature/personal-program.js" /* webpackChunkName: "component---src-pages-feature-personal-program-js" */),
  "component---src-pages-feature-real-life-talks-js": () => import("./../../../src/pages/feature/real-life-talks.js" /* webpackChunkName: "component---src-pages-feature-real-life-talks-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-document-template-js": () => import("./../../../src/templates/document-template.js" /* webpackChunkName: "component---src-templates-document-template-js" */)
}

