import { LEADS } from "./constants"

export const addLead = (lead, formId) => ({
  type: LEADS.ADD.IDLE,
  payload: {
    lead: { ...lead, timestamp: Date.now() },
    formId,
  },
})

export const addLeadSuccess = formId => {
  return {
    type: LEADS.ADD.SUCCESS,
    payload: { formId },
  }
}

export const addLeadFailure = (error, formId) => ({
  type: LEADS.ADD.FAILURE,
  payload: {
    error,
    formId,
  },
})
