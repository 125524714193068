export const scrollToForm = () => {
  document
    .getElementById("try-us-section")
    .scrollIntoView({ behavior: "smooth" })
}

export const scrollTo = id => {
  document.getElementById(id).scrollIntoView({ behavior: "smooth" })
}

export const pushToDataLayer = data => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(data)
}

export const isInUTCPlus03 = () => {
  var offsetInHours = new Date().getTimezoneOffset() / -60
  return offsetInHours === 3
}
