/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

import React from "react"
import { Provider } from "react-redux"
import configureStore from "store/index"
import "./src/assets/styles/index.scss"
import { showHeader } from "store/header/actions"

const store = configureStore()

export const wrapRootElement = ({ element }) => {
  return <Provider store={store}>{element}</Provider>
}

export const onRouteUpdate = () => {
  store.dispatch(showHeader())
}
