export const LEADS = {
  ADD: {
    IDLE: "LEADS_ADD_IDLE",
    SUCCESS: "LEADS_ADD_SUCCESS",
    FAILURE: "LEADS_ADD_FAILURE",
  },
  INIT: {
    IDLE: "LEADS_INIT_IDLE",
    SUCCESS: "LEADS_INIT_SUCCESS",
    FAILURE: "LEADS_INIT_FAILURE",
  },
  UPDATE: {
    IDLE: "LEADS_UPDATE_IDLE",
    SUCCESS: "LEADS_UPDATE_SUCCESS",
    FAILURE: "LEADS_UPDATE_FAILURE",
  },
  DELETE: {
    IDLE: "LEADS_DELETE_IDLE",
    SUCCESS: "LEADS_DELETE_SUCCESS",
    FAILURE: "LEADS_DELETE_FAILURE",
  },
}
