import { SHOW_HEADER, HIDE_HEADER } from "./constants"

export const showHeader = () => {
  return {
    type: SHOW_HEADER,
  }
}

export const hideHeader = () => ({
  type: HIDE_HEADER,
})
