export const formSubmitButtonEnum = {
  CREATE: "Додати",
  EDIT: "Зміни",
}

export const formModeEnum = {
  CREATE: "CREATE",
  EDIT: "EDIT",
}

export const FETCH_METHODS = {
  GET: "GET",
  POST: "POST",
}

export const mediaBreakpointsEnum = {
  XXS: 325,
  XS: 568,
  SM: 667,
  MD: 768,
  LG: 992,
  XL: 1200,
}

export const teacherSlugs = {
  MARTA_YAROSH: "marta-yarosh",
  ANN_KULCHUNSKA: "ann-kulchynska",
  MARIIA_DEMCHENKO: "mariia-demchenko",
  YULIIA_LOBODA: "yuliia-loboda",
}

export const LOCALES = {
  UA: "ua",
}

export const APP_STATES = {
  INIT: "init",
  LOADED: "loaded",
}

export const FETCH_STATUSES = {
  IDLE: "IDLE",
  REQUEST: "REQUEST",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
}
