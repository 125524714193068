import { createReducer } from "utils/store"
import { FORMS } from "forms/constants"

import { LEADS } from "./constants"

export const initialState = {
  data: [],
  error: "",
  forms: {
    [FORMS.MAIN_LEAD_FORM]: {
      isLoading: false,
      isSuccess: false,
      isFailed: false,
    },
    [FORMS.DRAWER_LEAD_FORM]: {
      isLoading: false,
      isSuccess: false,
      isFailed: false,
    },
  },
}

export const handlers = {
  [LEADS.ADD.IDLE]: (state, { payload }) => ({
    ...state,
    forms: {
      ...state.forms,
      [payload.formId]: {
        ...state.forms[payload.formId],
        isLoading: true,
      },
    },
  }),
  [LEADS.ADD.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      forms: {
        ...state.forms,
        [payload.formId]: {
          isSuccess: true,
          isLoading: false,
        },
      },
    }
  },
  [LEADS.ADD.FAILURE]: (state, { payload }) => ({
    ...state,
    forms: {
      ...state.forms,
      [payload.formId]: {
        ...state.forms[payload.formId],
        isLoading: false,
        isFailed: true,
      },
    },
    error: payload.error,
  }),
}

export default createReducer(initialState, handlers)
